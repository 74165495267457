import React from 'react';
import PropTypes from 'prop-types';
import DeviceCartridgeBar from './CartridgeBar';
import DeviceLarge from './Plotter';
import DevicePaperBar from './PaperBar';
import DevicePaperGauge from './PaperGauge';
import {
  LevelStates,
  SupplyNames,
  SupplyStates,
  SupplyStyles,
} from './constants';

const DeviceSupply = ({ supply, supplyStyle }) => {
  if (supply.supplyName === SupplyNames.LARGE) {
    return <DeviceLarge level={supply.level} />;
  }
  if (supply.supplyName === SupplyNames.PAGES) {
    return <DevicePaperGauge level={supply.level} maxLevel={supply.maxLevel} />;
  }
  if (supply.supplyName === SupplyNames.PAPER) {
    return <DevicePaperBar level={supply.level} />;
  }
  return (
    <DeviceCartridgeBar
      type={supply.supplyName}
      level={supply.level}
      levelState={supply.levelState}
      supplyState={supply.supplyState}
      supplyStyle={supplyStyle}
    />
  );
};

DeviceSupply.defaultProps = {
  supplyStyle: SupplyStyles.noDot,
};

DeviceSupply.propTypes = {
  supply: PropTypes.shape({
    supplyName: PropTypes.oneOf(Object.values(SupplyNames)),
    level: PropTypes.number,
    levelState: PropTypes.oneOf(Object.values(LevelStates)),
    supplyState: PropTypes.oneOf(Object.values(SupplyStates)),
    maxLevel: PropTypes.number,
  }).isRequired,
  supplyStyle: PropTypes.oneOf(Object.values(SupplyStyles)),
};

export default DeviceSupply;
