import React from 'react';
import PropTypes from 'prop-types';
import {
  Cartridge,
  CartridgeBar,
  CartridgeContainer,
  CartridgeInfo,
  CartridgeLevel,
  CartridgeLevelGrad,
} from './styles';
import {
  LevelStates,
  SupplyNames,
  SupplyStates,
  SupplyStyles,
} from '../constants';
import CartridgeDot from './CartridgeDot';

const colorMapping = {
  C: 'cyan',
  M: 'magenta',
  Y: 'yellow',
  K: 'black',
};

const DeviceCartridgeBar = ({
  type, level, levelState, supplyState, supplyStyle,
}) => {
  const hasCartridgeInfo = supplyStyle === SupplyStyles.inkDot;
  if (type === SupplyNames.CMY) {
    return (
      <CartridgeContainer data-testid={`printer-supplies-cartridge-${type}`}>
        {hasCartridgeInfo && (
          <CartridgeInfo className="caption-small cartridgeInfo tricolor">
            <CartridgeDot className="cartridgeDot tricolor yellow"><div /></CartridgeDot>
            <CartridgeDot className="cartridgeDot tricolor cyan"><div /></CartridgeDot>
            <CartridgeDot className="cartridgeDot tricolor magenta"><div /></CartridgeDot>
          </CartridgeInfo>
        )}
        <Cartridge
          className="cartridge tricolor"
          level={level}
          levelState={levelState}
          supplyState={supplyState}
        >
          <CartridgeBar className="cartridgeBar cyan">
            <CartridgeLevel className="cartridgeLevel">
              <CartridgeLevelGrad grad={0.25} />
              <CartridgeLevelGrad grad={0.5} />
              <CartridgeLevelGrad grad={0.75} />
            </CartridgeLevel>
          </CartridgeBar>
          <CartridgeBar className="cartridgeBar yellow">
            <CartridgeLevel className="cartridgeLevel">
              <CartridgeLevelGrad grad={0.25} />
              <CartridgeLevelGrad grad={0.5} />
              <CartridgeLevelGrad grad={0.75} />
            </CartridgeLevel>
          </CartridgeBar>
          <CartridgeBar className="cartridgeBar magenta">
            <CartridgeLevel className="cartridgeLevel">
              <CartridgeLevelGrad grad={0.25} />
              <CartridgeLevelGrad grad={0.5} />
              <CartridgeLevelGrad grad={0.75} />
            </CartridgeLevel>
          </CartridgeBar>
        </Cartridge>
      </CartridgeContainer>
    );
  }
  return (
    <CartridgeContainer data-testid={`printer-supplies-cartridge-${type}`}>
      {hasCartridgeInfo && (
        <CartridgeInfo className="caption-small cartridgeInfo">
          <CartridgeDot className={`cartridgeDot ${colorMapping[type]}`}>
            {type}
          </CartridgeDot>
        </CartridgeInfo>
      )}
      <Cartridge
        className="cartridge"
        level={level}
        levelState={levelState}
        supplyState={supplyState}
      >
        <CartridgeBar className={`cartridgeBar ${colorMapping[type]}`}>
          <CartridgeLevel className="cartridgeLevel">
            <CartridgeLevelGrad grad={0.15} />
            <CartridgeLevelGrad grad={0.35} />
            <CartridgeLevelGrad grad={0.65} />
          </CartridgeLevel>
        </CartridgeBar>
      </Cartridge>
    </CartridgeContainer>
  );
};

DeviceCartridgeBar.defaultProps = {
  level: undefined,
  levelState: undefined,
  supplyState: undefined,
  supplyStyle: SupplyStyles.noDot,
};

DeviceCartridgeBar.propTypes = {
  type: PropTypes.oneOf([
    SupplyNames.C,
    SupplyNames.M,
    SupplyNames.Y,
    SupplyNames.K,
    SupplyNames.CMY,
  ]).isRequired,
  level: PropTypes.number,
  levelState: PropTypes.oneOf(Object.values(LevelStates)),
  supplyState: PropTypes.oneOf(Object.values(SupplyStates)),
  supplyStyle: PropTypes.oneOf(Object.values(SupplyStyles)),
};

export default DeviceCartridgeBar;
