import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const SuppliesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SuppliesLabel = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.foreground.medium};
  margin-top: ${tokens.layout.size4};
`;

export const Supplies = styled.div`
  display: flex;
  gap: ${tokens.layout.size4};
`;
