import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  gap: ${Tokens.layout.size2};
`;

export default {
  Container,
};
