import React from 'react';
import PropTypes from 'prop-types';
import {
  ErrorWidget,
  LoaderWidget,
  useI18n,
  useRootContext,
} from '@jarvis/react-portal-addons';
import DeviceSupply from './supply';
import PlatformNotSupported from './PlatformNotSupported';
import {
  SupplyStates,
  SupplyStyles,
  SupplyTypesCustom,
  getSupplyNamesByType,
  getSupplyNamesOrder,
} from './constants';
import { Supplies, SuppliesContainer, SuppliesLabel } from './styles';
import useSuppliesApiCall from '../../hooks/useSuppliesApiCall';

const createSupply = supplyName => ({
  supplyName,
  level: 0,
  levelState: undefined,
  supplyState: SupplyStates.error,
  slot: 0,
});

const getSupplies = ({
  levels,
  supplyType,
}) => {
  if (!levels) {
    return { levels: [], supplyType };
  }
  const supplies = [...levels];
  // fallback to add missing known supply according to supply type
  const supplyNames = getSupplyNamesByType(supplyType);
  supplyNames.forEach(name => {
    if (levels.findIndex(supply => name === supply.supplyName) < 0) {
      supplies.push(createSupply(name));
    }
  });
  // sort supplies by slot number or fallback to default known order by supply type
  const supplyNamesOrder = getSupplyNamesOrder(supplyType);
  const supplySorter = (a, b) => (a.order === b.order
    ? supplyNamesOrder.indexOf(a.supplyName) - supplyNamesOrder.indexOf(b.supplyName)
    : a.order - b.order);
  return {
    levels: supplies.sort(supplySorter),
    supplyType,
  };
};

const DeviceSupplies = ({
  authProvider,
  deviceId,
  levels,
  modelNumber,
  platformIdentifier,
  stack,
  supplyStyle,
  supplyType,
}) => {
  const { featureFlags = {} } = useRootContext();
  const isGen1OrGen2 = ['gen1', 'gen2'].includes(platformIdentifier?.toLocaleLowerCase());

  const { t } = useI18n();

  const suppliesApiCall = useSuppliesApiCall({
    authProvider,
    deviceId,
    init: !isGen1OrGen2,
    levels,
    modelNumber,
    stack,
    supplyType,
    featureFlags,
  });

  if (isGen1OrGen2) {
    return (
      <SuppliesContainer>
        <PlatformNotSupported />
      </SuppliesContainer>
    );
  }

  if (suppliesApiCall.isFetching) {
    return (
      <SuppliesContainer>
        <LoaderWidget fullscreen />
      </SuppliesContainer>
    );
  }

  if (suppliesApiCall.error) {
    let errorProps = { message: t('errorWidget.defaultMessage') };
    if (suppliesApiCall.error?.message === 'Rule: No levels or empty') {
      errorProps = { message: t('printers.supplies.noDataAvailable') };
    }
    return (
      <SuppliesContainer>
        <ErrorWidget
          onRetry={suppliesApiCall.forceFetch}
          labelRetry={t('errorWidget.retry')}
          {...errorProps}
        />
      </SuppliesContainer>
    );
  }

  const supplies = getSupplies({
    levels: suppliesApiCall.data?.levels || levels,
    supplyType: suppliesApiCall.data?.supplyType || supplyType,
  });

  return (
    <SuppliesContainer data-testid="printer-supplies-container">
      <Supplies data-testid="printer-supplies">
        {supplies?.levels?.map(supply => (
          <DeviceSupply
            key={`supply_${supply.supplyName}_${supply.level}`}
            supply={supply}
            supplyStyle={supplyStyle}
          />
        ))}
      </Supplies>
      {supplies?.levels && supplies.levels.length > 0 && (
        <SuppliesLabel data-testid="estimated-levels-label" className="caption">
          {t('printers.supplies.estimatedSupplyLevels')}
        </SuppliesLabel>
      )}
    </SuppliesContainer>
  );
};

DeviceSupplies.defaultProps = {
  authProvider: null,
  deviceId: null,
  levels: null,
  modelNumber: null,
  platformIdentifier: null,
  stack: null,
  supplyStyle: SupplyStyles.inkDot,
  supplyType: null,
  featureFlags: {},
};

DeviceSupplies.propTypes = {
  authProvider: PropTypes.objectOf(PropTypes.any),
  deviceId: PropTypes.string,
  levels: PropTypes.arrayOf(DeviceSupply.propTypes.supply),
  modelNumber: PropTypes.string,
  platformIdentifier: PropTypes.string,
  stack: PropTypes.number,
  supplyStyle: PropTypes.oneOf(Object.values(SupplyStyles)),
  supplyType: PropTypes.oneOf(Object.values(SupplyTypesCustom)),
  featureFlags: PropTypes.objectOf(PropTypes.any),
};

export default DeviceSupplies;
