import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from '@jarvis/react-portal-addons';
import resources from '../../assets/locale/index';
import DeviceSupplies from './index';
import GenericThemeProvider from '../GenericThemeProvider/theme';

const DeviceSuppliesMFE = props => {
  const {
    shell: { v1: { localization } },
    featureFlags,
  } = props;

  return (
    <GenericThemeProvider>
      <RootProvider
        localization={localization}
        resources={resources}
        featureFlags={featureFlags}
      >
        <DeviceSupplies {...props} />
      </RootProvider>
    </GenericThemeProvider>
  );
};

DeviceSuppliesMFE.propTypes = {
  featureFlags: PropTypes.objectOf(PropTypes.any),
  shell: PropTypes.objectOf(PropTypes.any),
};

DeviceSuppliesMFE.defaultProps = {
  featureFlags: {},
  shell: {},
};

export default DeviceSuppliesMFE;
