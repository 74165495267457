import React from 'react';
import PropTypes from 'prop-types';
import Dot from './styles';

const CartridgeDot = ({ className, children }) => (
  <Dot className={className}>
    <div>
      {children}
    </div>
  </Dot>
);

CartridgeDot.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

CartridgeDot.defaultProps = {
  className: '',
  children: null,
};

export default CartridgeDot;
