import styled from 'styled-components';
import { UCDEMainTheme } from '../constants';

const isBorderVisible = level => level < 50;
const getBorderVisibleColor = level => (level > 0
  ? UCDEMainTheme.main.cartridgeLarge.borderWarning
  : UCDEMainTheme.main.cartridgeLarge.borderError);
const getBorderColor = level => (isBorderVisible(level) ? getBorderVisibleColor(level) : 'transparent');
const getDotBorder = type => UCDEMainTheme.main.cartridgeLarge[type].borderColor;
const getDotBackground = type => UCDEMainTheme.main.cartridgeLarge[type].color;

export const LargeWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 94px;
  width: 94px;
  border: 2px solid ${({ level }) => getBorderColor(level)};
  border-radius: 14px;

  * {
    box-sizing: border-box;
  }
`;

export const LargeRow = styled.div`
  white-space: nowrap;
  height: 30px;
  margin: 0;
`;

export const LargeDot = styled.div`
  width: 20px;
  height: 20px;
  border: 0.5px solid ${({ type }) => getDotBorder(type)};
  border-radius: 50%;
  background-color: ${({ type }) => getDotBackground(type)};
  display: inline-block;
  margin: 5px;
  float: left;
`;
