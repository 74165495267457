import { DeviceApiClient } from '@jarvis/web-stratus-client';
import { AxiosResponse } from 'axios';

export class DeviceApiClientExt extends DeviceApiClient {
  // jarvisWebHttpInstance: JarvisWebHttpClient
  // apiName: string = 'ws-hp.com/devices'
  // apiVersion: string = '1'

  /**
   * Retrieves summary of device identities, status and capabilities
   * (so we can get ultimatelly modelNumber)
   * @param cloudId
   * @returns Axios response object
   */
  async getDevice(cloudId : string): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      validationSchema: DeviceApiClientExt.getDeviceSchema(),
      url: `/${cloudId}`,
    });
  };

  /**
   * Retrieves supplies status for a device
   * @param cloudId
   * @returns Axios response object
   */
  async getDeviceSuppliesStatus(cloudId : string): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      validationSchema: DeviceApiClientExt.getDeviceSuppliesStatusSchema(),
      url: `/${cloudId}/suppliesStatus`,
    });
  };

  /**
   * Retrieves details for a given model number
   * @param modelNumber
   * @returns
   */
  async getModelDetails(modelNumber : string): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      validationSchema: DeviceApiClientExt.getModelDetailsSchema(),
      url: `/models/${modelNumber}`,
    });
  };

  static getDeviceSchema(): object {
    return {
      "$schema": "http://json-schema.org/schema#",
      "type": "object"
    };
  };

  static getDeviceSuppliesStatusSchema(): object {
    return {
      "$schema": "http://json-schema.org/schema#",
      "type": "object"
    };
  };

  static getModelDetailsSchema(): object {
    return {
      "$schema": "http://json-schema.org/schema#",
      "type": "object"
    };
  };
};
