import React from 'react';
import DeviceSuppliesMFE from '../src/components/DeviceSupplies/app';

const { name: namespace } = require('../package.json');

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = props => {
  const shellProps = window.Shell || {};

  return (
    <section id={namespace}>
      <DeviceSuppliesMFE
        shell={shellProps}
        {...props}
      />
    </section>
  );
};

export default Root;
