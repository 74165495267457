import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as VeneerThemeProvider, useTheme } from '@veneer/theme';
import PropTypes from 'prop-types';

const StyledThemeWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <StyledThemeProvider theme={theme}>
      {children}
    </StyledThemeProvider>
  );
};

const GenericThemeProvider = ({ children }) => (
  <VeneerThemeProvider>
    <StyledThemeWrapper>
      {children}
    </StyledThemeWrapper>
  </VeneerThemeProvider>
);

StyledThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

GenericThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GenericThemeProvider;
