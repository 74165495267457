import styled from 'styled-components';
import IconDocument from '@veneer/core/dist/scripts/icons/icon_document';

const isBorderVisible = level => level < 50;
const getBorderVisibleColor = level => (level > 0 ? '#e8701a' : '#d0021b');
const getBorderColor = level => (isBorderVisible(level) ? getBorderVisibleColor(level) : 'transparent');
const getLevelColor = filled => (filled ? '#58595b' : '#eeeeee');

export const IconPaper = styled(IconDocument)`
  && {
    fill: #58595b;
    display: block;
  }
`;

export const PaperSheetLevel = styled.div`
  box-sizing: border-box;
  width: 16px;
  height: 5px;
  background-color: ${({ filled }) => getLevelColor(filled)};
  border-radius: 4px;
  margin: 5px 4px;
`;

export const PaperSheetBar = styled.div`
  display: inline-block;
  position: relative;
  width: 28px;
  height: 82px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  border: 2px solid ${({ level }) => getBorderColor(level)};
`;
