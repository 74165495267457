import React from 'react';
import PropTypes from 'prop-types';
import { IconPaper, PaperSheetBar, PaperSheetLevel } from './styles';

const DevicePaperBar = ({ level }) => (
  <PaperSheetBar
    data-testid="printer-supplies-paper"
    level={level}
  >
    <IconPaper size={24} />
    <PaperSheetLevel filled={level > 80} />
    <PaperSheetLevel filled={level > 60} />
    <PaperSheetLevel filled={level > 40} />
    <PaperSheetLevel filled={level > 20} />
    <PaperSheetLevel filled={level > 0} />
  </PaperSheetBar>
);

DevicePaperBar.propTypes = {
  level: PropTypes.number.isRequired,
};

export default DevicePaperBar;
