import { pathOr } from 'ramda';

export const SupplyTypes = {
  ink: 'ink',
  toner: 'toner',
};

export const SupplyDelivery = {
  cartridge: 'cartridge',
  tank: 'tank',
};

export const LevelStates = {
  ok: 'ok',
  low: 'low',
  veryLow: 'veryLow',
  veryVeryLow: 'veryVeryLow',
  depleted: 'depleted',
  fulfillment: 'fulfillment',
  unknown: 'unknown',
};

export const SupplyColors = {
  CYAN: '#009ACE',
  BLACK: '#000000',
  MAGENTA: '#E31C79',
  YELLOW: '#FFD100',
};

export const SupplyStates = {
  ok: 'ok',
  inform: 'inform',
  warning: 'warning',
  error: 'error',
};

export const SupplyTypesCustom = {
  INK: 'ink',
  MONO: 'mono',
  TONER: 'toner',
  TANK: 'tank',
  TRICOLOR: 'tricolor',
  LARGE: 'large', // prototype
  PAGES: 'pages', // prototype
  PAPER: 'paper', // prototype
};

export const SupplyNames = {
  C: 'C',
  M: 'M',
  Y: 'Y',
  CMY: 'CMY',
  K: 'K',
  LARGE: 'large', // prototype
  PAGES: 'pages', // prototype
  PAPER: 'paper', // prototype
};

export const SupplyColorSupported = {
  MONO: 'mono',
  COLOR: 'color',
};

const SuppliesTricolor = [
  SupplyNames.CMY,
  SupplyNames.K,
];

const SuppliesCOLOR = [
  SupplyNames.C,
  SupplyNames.M,
  SupplyNames.Y,
  SupplyNames.K,
];

const SuppliesMONO = [SupplyNames.K];

const SupplyNamesByType = {
  DEFAULT: [],
  ink: SuppliesCOLOR,
  mono: SuppliesMONO,
  tank: SuppliesCOLOR,
  toner: SuppliesCOLOR,
  tricolor: SuppliesTricolor,
};

export const getSupplyNamesByType = supplyType => pathOr(SupplyNamesByType.DEFAULT, [supplyType], SupplyNamesByType);

const SupplyNamesOrder = {
  DEFAULT: [
    SupplyNames.CMY,
    SupplyNames.C,
    SupplyNames.M,
    SupplyNames.Y,
    SupplyNames.K,
    SupplyNames.LARGE,
    SupplyNames.PAGES,
    SupplyNames.PAPER,
  ],
  ink: SuppliesCOLOR,
  mono: SuppliesMONO,
  tank: SuppliesCOLOR,
  toner: SuppliesCOLOR,
  tricolor: SuppliesTricolor,
};

export const getSupplyNamesOrder = supplyType => pathOr(SupplyNamesOrder.DEFAULT, [supplyType], SupplyNamesOrder);

export const SupplyStyles = {
  inkDot: 'inkDot',
  noDot: 'noDot',
};

export const UCDEMainTheme = {
  breakPoints: {
    sm: '375px',
    md: '600px',
    lg: '1036px',
    xg: '1280px',
  },
  main: {
    cartridge: {
      cyan: '#03a4f0',
      magenta: '#f7448b',
      yellow: '#ffd906',
      black: '#000000',
    },
    cartridgeLarge: {
      cyan: { color: '#03a4f0', borderColor: '#70707000' },
      magenta: { color: '#f7448b', borderColor: '#70707000' },
      yellow: { color: '#ff9d06', borderColor: '#70707000' },
      black: { color: '#000000', borderColor: '#70707000' },
      lightGray: { color: '#bbbbbb', borderColor: '#70707000' },
      darkGray: { color: '#303030', borderColor: '#70707000' },
      red: { color: '#be3754', borderColor: '#70707000' },
      green: { color: '#1ab77c', borderColor: '#70707000' },
      white: { color: '#ffffff', borderColor: '#4e4e4e' },
      borderWarning: '#e8701a',
      borderError: '#d0021b',
    },
  },
  hp: {
    color: '#027AAE',
    lightColor: '#F2F8FB',
    fontColor: '#464D50',
    fontSize: '16px',
    link: '#027AAE',
    linkHover: '#006691',
    linkPressed: '#004F74',
  },
};
